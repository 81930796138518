//
// Breadcrumbs
// --------------------------------------------------
$breadcrumb-padding-vertical:   8px;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-bg:                 #f5f5f5;
$breadcrumb-color:              $primary;
$breadcrumb-active-color:       $gray-light;
$breadcrumb-separator:          "/";

.breadcrumbs-custom {
  position: relative;
  background: $gray-lighter;

  img {
    width: 100%;
  }
}

.breadcrumbs-custom__inner {
  padding: 20px 0;
  text-align: center;

  > * + * {
    margin-top: 10px;
  }
}

.breadcrumbs-custom__title {
  font-size: 20px;
  line-height: 1.2;
  color: $black;
}

.breadcrumbs-custom__path {
  > li {
    display: inline-block;
    vertical-align: baseline;
    font-size: 12px;
    line-height: 1.3;
  }

  a {
    position: relative;
    top: -1px;
  }

  > li.active {
    color: $black;

    a {
      color: inherit;
      pointer-events: none;
    }
  }

  > li + li {
    &:before {
      position: relative;
      left: -1px;
      display: inline-block;
      padding: 0 5px;
      content: $breadcrumb-separator;
      vertical-align: baseline;
    }
  }

  a {
    display: inline;
    vertical-align: middle;
    &,
    &:active,
    &:focus {
      color: $breadcrumb-color;
    }

    &:hover {
      color: $black;
    }
  }
}

@include media-breakpoint-up(md) {
  .breadcrumbs-custom__inner {
    @include display-flex;
    @include flex-direction(row);
    @include align-items(center);
    @include justify-content(space-between);
    margin-left: -15px;
    margin-right: -15px;

    > * {
      padding: 0 15px;
    }

    > * + * {
      margin-top: 0;
    }
  }


  .breadcrumbs-custom__title {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }
}