//
// Sections
// --------------------------------------------------

// Section Spacing
// -------------------------

.section-xs,
.section-sm {
  padding-top: 35px;
  padding-bottom: 35px;
}

.section-md,
.section-lg,
.section-xl {
  padding-top: 60px;
  padding-bottom: 60px;
}

.section-xxl{
  padding-top: 75px;
  padding-bottom: 75px;
}

.section-collapse + .section-collapse {
  padding-top: 0;
}

@include media-breakpoint-down(sm) {
  .section-xs,
  .section-sm,
  .section-md,
  .section-lg,
  .section-xl {
    &:first-of-type {
      padding-top: 40px;
    }
  }
}

@include media-breakpoint-up(md) {
  .section-xs {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .section-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section-md {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  .section-lg {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .section-xl {
    padding-top: 95px;
    padding-bottom: 95px;
  }

  .section-alternate-1 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
}

@include media-breakpoint-up(lg) {
  .section-md {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  .section-lg {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-xl {
    padding-top: 130px;
    padding-bottom: 130px;
  }
}

@media (min-width: map_get($grid-breakpoints, xl)) and (min-height: $screen-height-desktop) {
  .section-md {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .section-lg {
    padding-top: 135px;
    padding-bottom: 135px;
  }

  .section-xl {
    padding-top: 190px;
    padding-bottom: 190px;

    &.section-xl_bigger {
      padding-top: 210px;
      padding-bottom: 210px;
    }
  }

  .section-xxl {
    padding-top: 165px;
    padding-bottom: 190px;
  }

  .section-xs,
  .section-sm,
  .section-md,
  .section-lg{
    &:first-of-type {
      padding-top: 140px;
    }
  }
}

// Custom
.section-divided {
}

.section-divided__main,
.section-divided__aside {
  > *:first-child {
    padding-top: 0;
  }

  > *:last-child {
    padding-bottom: 0;
  }

  > * + * {
    border-top: 1px solid $gray-1;
  }
}

.section-divided__aside {
  h5 + *,
  .heading-5 + * {
    margin-top: 23px;
  }

  * + h5 {
    margin-top: 30px;
  }

  @include media-breakpoint-between(md, md) {
    @include display-flex;
    @include flex-direction(row);
    @include flex-wrap(wrap);
    margin-left: -25px;
    margin-right: -25px;

    > [class*='section'] {
      width: calc(50% - 50px);
      margin-left: 25px;
      margin-right: 25px;

      &:nth-child(2) {
        padding-top: 0;
        border-top: 0;
      }
    }
    > .section-style-1 {
      width: 100%;
    }
  }

  @include media-breakpoint-up(xl) {
    > * {
      max-width: 370px;
      margin-left: 50px;
    }
  }
  
  &.section__aside-left {
    @include media-breakpoint-up(xl) {
      > * {
        margin-left: 0;
        margin-right: 50px;
      }
    }
  }
}

.section-divided__aside-left {
  @include media-breakpoint-up(xl) {
    position: relative;
    &:after{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 25px;
      content: "";
      display: inline-block;
      border-left: 1px solid $gray-1;
    }
  }
}

.section-divided__main-left {
  @include media-breakpoint-up(xl) {
    position: relative;
    &:after{
      position: absolute;
      top: 0;
      bottom: 0;
      left: -25px;
      content: "";
      display: inline-block;
      border-left: 1px solid $gray-1;
    }
  }
}



//fullwidth page
//

.fullwidth-page {
  @include display-flex;
  @include align-items(center);
  min-height: 100vh;

  .section-sm {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  
  > * {
    flex-basis: 100%;
  }

  * + .countdown {
    margin-top: 45px;
  }
  * + h5 {
    margin-top: 35px;
    @media (min-width: 768px) {
      margin-top: 55px;
    }
  }

  &_mod-1{
    min-height: 90vh;
  }
}

.ie-10,
.ie-11 {
  .fullwidth-page {
    white-space: nowrap;

    > * {
      display: inline-block;
      width: 100%;
      white-space: normal;
    }

    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      min-height: inherit;
    }
  }
}

// Novi section
[data-x-mode="true"] .novi-section {
  position: relative;
  z-index: 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    z-index: 10;
    height: 30px;
  }
}

.frame-header {
  width: 100%;
  height: 350px;
  outline: none;
  border: 0;

  @include media-breakpoint-up(md) {
    height: 500px;
  }

  @include media-breakpoint-up(xl) {
    height: 700px;
  }
}