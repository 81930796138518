/*
*
* Time Circles
* --------------------------------------------------
*/


/**
 *	This element is created inside your target element
 *	It is used so that your own element will not need to be altered
 **/
.time_circles {
  position: relative;
  width: 100%;
  height: 100%;
}

.DateCountdown{
  margin: 0 auto;
  max-width: 820px;
}

/**
 *	This is all the elements used to house all text used
 * in time circles
 **/
.time_circles > div {
  position: absolute;
  text-align: center;
  top: 50% !important;
  transform: translateY(-63%) !important;
  -webkit-transform: translateY(-63%) !important;
}

@media (min-width: 1200px) {
  #DateCountdown {
    width: 100%;
  }
}

/**
 *	Titles (Days, Hours, etc)
 **/
.time_circles > div > h4 {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0;
  margin: 0;
  text-align: center;
  font-size: 15px !important;
  font-weight: 400;
  top: 16vw;
}

.time_circles > div > h4 + * {
  margin-top: 0;
}

@media (min-width: 480px) {
  .time_circles > div > h4 {
    top: 75px;
  }
}

@media (min-width: 768px) {
  .time_circles > div > h4 {
    top: 94px;
  }
}

/**
 *	Time numbers, ie: 12
 **/
.time_circles > div > span {
  display: block;
  font-size: 30px;
  text-align: center;
  font-weight: 700;
}

@media (min-width: 768px) {
  .time_circles > div > span {
    font-size: 36px !important;
  }
}

@media (min-width: 1200px) {
  .time_circles > div > span {
    font-size: 48px !important;
  }
}
