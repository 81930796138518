/*
* @subsection Google Map
* @description Describes style declarations for RD Google Map extension
* @version     1.0.0
*/
$map-height: 200px;
$map-xs-height: 300px;
$map-md-height: 640px;

.google-map-markers {
  display: none;
}

.google-map-container {
  width: 100%;
}
.google-map {
  height: $map-height;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: $map-xs-height;
  }

  @include media-breakpoint-up(lg) {
    height: $map-md-height;
  }
}

.google-map-container-1 {
  .google-map {
    height: 300px;
    width: 100%;

    @include media-breakpoint-up(sm) {
      height: 300px;
    }

    @include media-breakpoint-up(lg) {
      height: 300px;
    }
  }
}

.gm-style-iw > div > div {
  padding-right: 10px;
  font-size: 13px;
}

* + .google-map-container { margin-top: 30px; }

@include media-breakpoint-up(lg) {
  * + .google-map-container { margin-top: 50px; }
  * + .google-map-container-1 { margin-top: 40px; }
}