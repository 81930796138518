//
// Buttons
// --------------------------------------------------

// Button General Styles
.button {
  display: inline-block;
  width: auto;
  max-width: 100%;
  border: 1px solid;
  border-radius: 3px;
  @include btn-size(12px, 30px, $btn-font-size, 24px);
  font-family: $btn-font-family;
  font-weight: $btn-font-weight;
  letter-spacing: .06em;
  transition: .33s all ease;
  white-space: normal;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;

  @include media-breakpoint-up(lg) {
    @include btn-size(16px, 40px, $btn-font-size, 24px);
  }
}

span.button {
  cursor: default;
}

* + .button {
  margin-top: 25px;
  @include media-breakpoint-up(md) {
    margin-top: 35px;
  }
}

// Appearance style
// --------------------------------------------------

.page {
  .button-default {
    @include btn-variant($gray-darker, transparent, $gray, $white, $gray-darker, $gray-darker);
  }

  .button-black {
    @include btn-variant($white, $black, $black, $white, $primary, $primary);
  }

  .button-primary {
    @include btn-variant($white, $primary, $primary, $white, $black, $black);
  }

  .button-gray-light-outline {
    @include btn-variant($black, transparent, $gray-light, $white, $primary, $primary);
  }

  span.button-gray-light-outline {
    @include btn-variant($black, transparent, $gray-light, $black, transparent, $gray-light);
  }
}

// Button Sizes
// --------------------------------------------------

.button-xs {
  @include btn-size(5px, 25px, 12px, 22px);
}

.button-sm {
  @include btn-size(6px, 25px, 13px, 24px);
}

.button-lg {
  @include btn-size(18px, 45px, 16px, 24px);

  @include media-breakpoint-up(xl) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.button-xl {
  @include btn-size(20px, 50px, 18px, 28px);

  @include media-breakpoint-up(xl) {
    padding-left: 75px;
    padding-right: 75px;
  }
}

// Button Shapes
// --------------------------------------------------

.button-circle {
  border-radius: 35px;
}

.button-square {
  border-radius: 0;
}

.button-round-2 {
  border-radius: 8px;
}

// Button Types
// --------------------------------------------------
.button-link {
  border: 0;
  padding: 0;
  @include link($black, $primary);

  &:after {
    display: inline-block;
    padding-left: 8px;
    content: '\e944';
    font-family: 'Linearicons';
  }
}

// Button Icon styles
// --------------------------------------------------

.page .button {
  &.button-icon {
    .icon {
      position: relative;
      top: -.21em;
      display: inline-block;
      vertical-align: middle;
      color: inherit;
      font-size: 1.85em;
      line-height: 0;
    }

    &-left {
      .icon {
        padding-right: 11px;
      }
    }

    &-right {
      @include flex-direction(row-reverse);
      .icon {
        padding-left: 11px;
      }
    }
  }
}

// Button other styles
// --------------------------------------------------
.button-block {
  display: block;
  width: 100%;
}

// bootstrap buttons

.btn-primary:active:hover {
  background-color: $primary;
  border-color: $primary;
}