//
// Blurb
// --------------------------------------------------

// Blurb
.blurb {
  img{
    width: auto;
  }
}

.blurb__title {
  font-size: 18px;
  line-height: 1.33;
  color: $black;
}

* + .blurb__title {
  margin-top: 16px;
}

@include media-breakpoint-up(xl) {
  .blurb__title {
    font-size: $h5-font-size;
    line-height: $h5-line-height;
  }

  * + .blurb__title {
    margin-top: 45px;
  }
}

// Blurb circle
.blurb-circle {
  text-align: left;

  .unit {
    .blurb-circle__icon {
      position: relative;
      top: 3px;
    }
  }
}

.blurb-circle__icon {
  .icon {
    width: 2.4em;
    height: 2.4em;
    border: 1px solid $primary;
    border-radius: 50%;
    text-align: center;
    color: $primary;
    font-size: 30px;
    line-height: 2.33em;
    
    &::before {
      line-height: inherit;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blurb-circle__icon .icon {
    font-size: 36px;
  }
}

@include media-breakpoint-up(xxl) {
  .blurb-circle__icon .icon {
    font-size: 44px;
  }
}

.blurb-circle.blurb-circle_centered {
  max-width: 360px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@include media-breakpoint-down(sm) {
  .blurb-circle{
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;
  }
}

// Blurb minimal
.blurb-minimal {
  text-align: left;
  .unit {
    .blurb-minimal__icon .icon {
      font-size: 32px;
    }
  }
}

.blurb-minimal__icon .icon {
  font-size: 30px;
  color: $primary;
}

.blurb-minimal.blurb-minimal_black {
  &, .blurb-minimal__icon .icon {
    color: $black;
  }
}

@include media-breakpoint-up(xl) {
  .blurb-minimal__icon .icon {
    font-size: 36px;
  }
}

@include media-breakpoint-up(xxl) {
  .blurb-minimal__icon .icon {
    font-size: 44px;
  }
}